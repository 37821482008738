$icomoon-font-family: 's4b' !default;
$icomoon-font-path: '../icon-fonts' !default;

$icon-icons-pause: '\e956';
$icon-icons-play: '\e957';
$icon-icons-menu: '\e955';
$icon-credit-cards-amex-path1: '\e900';
$icon-credit-cards-amex-path2: '\e901';
$icon-credit-cards-amex-path3: '\e902';
$icon-credit-cards-amex-path4: '\e903';
$icon-credit-cards-amex-path5: '\e904';
$icon-credit-cards-amex-path6: '\e905';
$icon-credit-cards-mastercard-path1: '\e906';
$icon-credit-cards-mastercard-path2: '\e907';
$icon-credit-cards-mastercard-path3: '\e908';
$icon-credit-cards-mastercard-path4: '\e909';
$icon-credit-cards-mastercard-path5: '\e90a';
$icon-credit-cards-mastercard-path6: '\e90b';
$icon-credit-cards-visa-path1: '\e90c';
$icon-credit-cards-visa-path2: '\e90d';
$icon-credit-cards-visa-path3: '\e90e';
$icon-credit-cards-visa-path4: '\e90f';
$icon-credit-cards-visa-path5: '\e910';
$icon-credit-cards-visa-path6: '\e911';
$icon-lock-reverse: '\e913';
$icon-icona-check-rounded: '\e914';
$icon-icona-rimuovi: '\e915';
$icon-icona-rimuovi-copy-1: '\e916';
$icon-icona-collection-copy-1: '\e917';
$icon-icona-aggiungi: '\e918';
$icon-collection: '\e919';
$icon-commenti: '\e91a';
$icon-check: '\e91b';
$icon-speak: '\e91c';
$icon-isp-logo: '\e91d';
$icon-exit: '\e91e';
$icon-calendar: '\e91f';
$icon-hamburger: '\e920';
$icon-star: '\e921';
$icon-clock1: '\e922';
$icon-close-x: '\e923';
$icon-modifica: '\e924';
$icon-notifica: '\e926';
$icon-ricerca: '\e927';
$icon-visualizzazioni: '\e928';
$icon-heart: '\e929';
$icon-app-store: '\e92a';
$icon-uniE913: '\e92b';
$icon-uniE914: '\e92c';
$icon-uniE915: '\e92d';
$icon-decrease: '\e92e';
$icon-uniE917: '\e92f';
$icon-grow: '\e930';
$icon-help: '\e931';
$icon-info: '\e932';
$icon-uniE91B: '\e933';
$icon-uniE91E: '\e934';
$icon-uniE91F: '\e935';
$icon-uniE920: '\e936';
$icon-uniE921: '\e937';
$icon-uniE922: '\e938';
$icon-uniE923: '\e939';
$icon-uniE924: '\e93a';
$icon-uniE925: '\e93b';
$icon-uniE927: '\e93d';
$icon-uniE928: '\e93e';
$icon-play: '\e93f';
$icon-arrow-right: '\e940';
$icon-btn-arrow: '\e941';
$icon-filter: '\e942';
$icon-plus: '\e943';
$icon-foto: '\e944';
$icon-griglia: '\e945';
$icon-lista: '\e946';
$icon-icona-chevron-giu-active: '\e952';
$icon-icona-like: '\e947';
$icon-uniE937: '\e948';
$icon-uniE938: '\e949';
$icon-bookmark: '\e94a';
$icon-icona-play: '\e94b';
$icon-icona-lucchetto: '\e94c';
$icon-icona-allega: '\e94d';
$icon-icona-add-user1: '\e94e';
$icon-uniE940: '\e94f';
$icon-icona-salva-attivo: '\e951';
$icon-icona-elimina: '\e99a';
$icon-clock: '\e954';
$icon-note: '\e925';
$icon-manager: '\e953';
$icon-send: '\e93c';
$icon-icona-info-active: '\e912';
$icon-icona-salva-active: '\e950';
