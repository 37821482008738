body {
  @media screen and (max-width: 320px) {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  &.disabled-scroll {
    overflow: hidden;
  }
}

.container-fluid {
  margin: 0 auto;

  &.content-wrapper {
    padding: 0;
  }

  &.container {
    max-width: 1280px;
  }
}

.animated-underline {
  color: #ffffff;
  background-image: linear-gradient(90deg,#fff 0,#fff 98%);
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: left 85%;
  transition: all .5s ease;
  padding-bottom: 5px;
  &:hover,
  &:focus {
    background-size: 100% 1px;
  }
}
