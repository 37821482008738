@import 'icon-variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hvsg6g');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?hvsg6g')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hvsg6g#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?hvsg6g')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?hvsg6g##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icons-pause {
  &:before {
    content: $icon-icons-pause;
  }
}
.icon-icons-play {
  &:before {
    content: $icon-icons-play;
  }
}
.icon-icons-menu {
  &:before {
    content: $icon-icons-menu;
  }
}
.icon-credit-cards-amex .path1 {
  &:before {
    content: $icon-credit-cards-amex-path1;
    color: rgb(42, 160, 208);
  }
}
.icon-credit-cards-amex .path2 {
  &:before {
    content: $icon-credit-cards-amex-path2;
    margin-left: -1.5em;
    color: rgb(42, 160, 208);
  }
}
.icon-credit-cards-amex .path3 {
  &:before {
    content: $icon-credit-cards-amex-path3;
    margin-left: -1.5em;
    color: rgb(42, 160, 208);
  }
}
.icon-credit-cards-amex .path4 {
  &:before {
    content: $icon-credit-cards-amex-path4;
    margin-left: -1.5em;
    color: rgb(255, 255, 255);
  }
}
.icon-credit-cards-amex .path5 {
  &:before {
    content: $icon-credit-cards-amex-path5;
    margin-left: -1.5em;
    color: rgb(255, 255, 255);
  }
}
.icon-credit-cards-amex .path6 {
  &:before {
    content: $icon-credit-cards-amex-path6;
    margin-left: -1.5em;
    color: rgb(255, 255, 255);
    opacity: 0.05;
  }
}
.icon-credit-cards-mastercard .path1 {
  &:before {
    content: $icon-credit-cards-mastercard-path1;
    color: rgb(67, 97, 195);
  }
}
.icon-credit-cards-mastercard .path2 {
  &:before {
    content: $icon-credit-cards-mastercard-path2;
    margin-left: -1.36328125em;
    color: rgb(255, 175, 56);
  }
}
.icon-credit-cards-mastercard .path3 {
  &:before {
    content: $icon-credit-cards-mastercard-path3;
    margin-left: -1.36328125em;
    color: rgb(255, 175, 56);
  }
}
.icon-credit-cards-mastercard .path4 {
  &:before {
    content: $icon-credit-cards-mastercard-path4;
    margin-left: -1.36328125em;
    color: rgb(235, 54, 54);
  }
}
.icon-credit-cards-mastercard .path5 {
  &:before {
    content: $icon-credit-cards-mastercard-path5;
    margin-left: -1.36328125em;
    color: rgb(255, 175, 56);
  }
}
.icon-credit-cards-mastercard .path6 {
  &:before {
    content: $icon-credit-cards-mastercard-path6;
    margin-left: -1.36328125em;
    color: rgb(255, 255, 255);
    opacity: 0.05;
  }
}
.icon-credit-cards-visa .path1 {
  &:before {
    content: $icon-credit-cards-visa-path1;
    color: rgb(249, 249, 249);
  }
}
.icon-credit-cards-visa .path2 {
  &:before {
    content: $icon-credit-cards-visa-path2;
    margin-left: -1.5em;
    color: rgb(9, 89, 158);
  }
}
.icon-credit-cards-visa .path3 {
  &:before {
    content: $icon-credit-cards-visa-path3;
    margin-left: -1.5em;
    color: rgb(229, 151, 3);
  }
}
.icon-credit-cards-visa .path4 {
  &:before {
    content: $icon-credit-cards-visa-path4;
    margin-left: -1.5em;
    color: rgb(255, 255, 255);
    opacity: 0.05;
  }
}
.icon-credit-cards-visa .path5 {
  &:before {
    content: $icon-credit-cards-visa-path5;
    margin-left: -1.5em;
    color: rgb(0, 88, 159);
  }
}
.icon-credit-cards-visa .path6 {
  &:before {
    content: $icon-credit-cards-visa-path6;
    margin-left: -1.5em;
    color: rgb(249, 165, 26);
  }
}
.icon-lock-reverse {
  &:before {
    content: $icon-lock-reverse;
  }
}
.icon-icona-check-rounded {
  &:before {
    content: $icon-icona-check-rounded;
  }
}
.icon-icona-rimuovi {
  &:before {
    content: $icon-icona-rimuovi;
  }
}
.icon-icona-rimuovi-copy-1 {
  &:before {
    content: $icon-icona-rimuovi-copy-1;
  }
}
.icon-icona-collection-copy-1 {
  &:before {
    content: $icon-icona-collection-copy-1;
  }
}
.icon-icona-aggiungi {
  &:before {
    content: $icon-icona-aggiungi;
  }
}
.icon-collection {
  &:before {
    content: $icon-collection;
  }
}
.icon-commenti {
  &:before {
    content: $icon-commenti;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-speak {
  &:before {
    content: $icon-speak;
  }
}
.icon-isp-logo {
  &:before {
    content: $icon-isp-logo;
  }
}
.icon-exit {
  &:before {
    content: $icon-exit;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-clock1 {
  &:before {
    content: $icon-clock1;
  }
}
.icon-close-x {
  &:before {
    content: $icon-close-x;
  }
}
.icon-modifica {
  &:before {
    content: $icon-modifica;
  }
}
.icon-notifica {
  &:before {
    content: $icon-notifica;
  }
}
.icon-ricerca {
  &:before {
    content: $icon-ricerca;
  }
}
.icon-visualizzazioni {
  &:before {
    content: $icon-visualizzazioni;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
  }
}
.icon-app-store {
  &:before {
    content: $icon-app-store;
  }
}
.icon-uniE913 {
  &:before {
    content: $icon-uniE913;
  }
}
.icon-uniE914 {
  &:before {
    content: $icon-uniE914;
  }
}
.icon-uniE915 {
  &:before {
    content: $icon-uniE915;
  }
}
.icon-decrease {
  &:before {
    content: $icon-decrease;
  }
}
.icon-uniE917 {
  &:before {
    content: $icon-uniE917;
  }
}
.icon-grow {
  &:before {
    content: $icon-grow;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-uniE91B {
  &:before {
    content: $icon-uniE91B;
  }
}
.icon-uniE91E {
  &:before {
    content: $icon-uniE91E;
  }
}
.icon-uniE91F {
  &:before {
    content: $icon-uniE91F;
  }
}
.icon-uniE920 {
  &:before {
    content: $icon-uniE920;
  }
}
.icon-uniE921 {
  &:before {
    content: $icon-uniE921;
  }
}
.icon-uniE922 {
  &:before {
    content: $icon-uniE922;
  }
}
.icon-uniE923 {
  &:before {
    content: $icon-uniE923;
  }
}
.icon-uniE924 {
  &:before {
    content: $icon-uniE924;
  }
}
.icon-uniE925 {
  &:before {
    content: $icon-uniE925;
  }
}
.icon-uniE927 {
  &:before {
    content: $icon-uniE927;
  }
}
.icon-uniE928 {
  &:before {
    content: $icon-uniE928;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-btn-arrow {
  &:before {
    content: $icon-btn-arrow;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-foto {
  &:before {
    content: $icon-foto;
  }
}
.icon-griglia {
  &:before {
    content: $icon-griglia;
  }
}
.icon-lista {
  &:before {
    content: $icon-lista;
  }
}
.icon-icona-chevron-giu-active {
  &:before {
    content: $icon-icona-chevron-giu-active;
  }
}
.icon-icona-like {
  &:before {
    content: $icon-icona-like;
  }
}
.icon-uniE937 {
  &:before {
    content: $icon-uniE937;
  }
}
.icon-uniE938 {
  &:before {
    content: $icon-uniE938;
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark;
  }
}
.icon-icona-play {
  &:before {
    content: $icon-icona-play;
  }
}
.icon-icona-lucchetto {
  &:before {
    content: $icon-icona-lucchetto;
  }
}
.icon-icona-allega {
  &:before {
    content: $icon-icona-allega;
  }
}
.icon-icona-add-user1 {
  &:before {
    content: $icon-icona-add-user1;
  }
}
.icon-uniE940 {
  &:before {
    content: $icon-uniE940;
  }
}
.icon-icona-salva-attivo {
  &:before {
    content: $icon-icona-salva-attivo;
  }
}
.icon-icona-elimina {
  &:before {
    content: $icon-icona-elimina;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-note {
  &:before {
    content: $icon-note;
  }
}
.icon-manager {
  &:before {
    content: $icon-manager;
  }
}
.icon-send {
  &:before {
    content: $icon-send;
  }
}
.icon-icona-info-active {
  &:before {
    content: $icon-icona-info-active;
  }
}
.icon-icona-salva-active {
  &:before {
    content: $icon-icona-salva-active;
  }
}
